.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	width: fit-content;
	padding: 10px 20px;
	margin: auto;
	margin-bottom: 50px;
	background-color: #2a2b2d;
	box-sizing: border-box;
}

.pagination div {
	padding: 5px 10px;
	cursor: pointer;
	border: #8e949e 1px solid;
	font-size: 20px;
}

.pagination div:hover {
	background-color: #3a3b3d;
}

.paginationLeftArrow {
	cursor: pointer;
	rotate: 180deg;
}

.paginationArrow {
	height: 20px;
}

@media screen and (max-width: 500px) {
	.pagination {
		width: 90%;
		padding: 10px 0;
	}
	.pagination div {
		padding: 5px 7px;
		font-size: 16px;
	}
	.paginationArrow {
		height: 15.5px;
	}
}
