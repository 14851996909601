.itemContainer {
	background-color: #2a2b2d;
	border-radius: 10px;
	box-sizing: border-box;
	box-shadow: 0 16px 32px 0 hsla(0, 0%, 7%, 0.2);
	color: #f4f4f3;
	font-size: 15px;
	margin: 40px auto;
	padding: 10px;
	transition-duration: 1s;
	width: 90%;
	display: flex;
}

.productImg {
	width: 250px !important;
	border-radius: 10px;
	background-color: #f1f1f1;
	margin-right: 20px;
	cursor: pointer;
}

.productName {
	font-size: 20px;
	font-weight: bold;
	padding-bottom: 10px;
	cursor: pointer;
}

.productPriceBox {
	display: flex;
	align-items: center;
	cursor: pointer;
	padding-bottom: 10px;
}

.productPriceBox div {
	margin-right: 10px;
}

.productPrice {
	font-size: 25px;
	font-weight: bold;
}

.productMRP {
	color: #f4f4f3;
	font-size: 20px;
	text-decoration: line-through;
}

.product_discount {
	color: lightgreen;
	font-weight: bold;
	font-size: 20px;
}

.product_source_icon {
	height: 40px;
	margin-top: 20px;
	position: relative;
	bottom: 0;
	margin-right: 5px;
	text-align: right;
}

@media screen and (max-width: 800px) {
	.itemContainer {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
	.productImg {
		width: 100%;
		margin-right: 0;
		margin-bottom: 20px;
	}
	.productName {
		margin-bottom: 20px;
	}
	.productPriceBox {
		justify-content: center;
	}
	.productPrice {
		font-size: 30px;
	}
	.productMRP {
		font-size: 25px;
	}
	.product_discount {
		font-size: 25px;
	}
	.product_source_icon {
		margin: 10px 0;
	}
}

@media screen and (max-width: 500px) {
	.productName {
		font-size: 18px;
	}
	.productPrice {
		font-size: 25px;
	}
	.productMRP {
		font-size: 20px;
	}
	.product_discount {
		font-size: 20px;
	}
	.product_source_icon {
		height: 30px;
	}
}
